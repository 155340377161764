import React, { useState } from 'react'
import { colors } from '~/design'
import styled from 'styled-components'
import useMedia from 'react-use/lib/useMedia'
import ArrowUpIcon from '~/components/icons/arrows/48px_triangle-up-19.svg'
import ArrowDownIcon from '~/components/icons/arrows/48px_triangle-down-20.svg'
import ClipLoader from 'react-spinners/ClipLoader'
import InfoIcon from '~/components/icons/ui/16px_round-e-info.svg'
import { Level } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import useAPI from '~/prix/react/hooks/api'

const Header = styled.div`
  padding-left: 70px;
  background: ${colors.sebraeBlue};

  @media (max-width: 768px) {
    padding-left: unset;
  }

  .top-card {
    background: ${colors.sebraeBlue};
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .top-card-title {
      margin-left: 48px;
      display: flex;
      flex-direction: column;
      line-height: normal;
      color: #fff;
      justify-content: center;
      margin-bottom: 4px;

      h1 {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
      }

      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }

    @media (max-width: 768px) {
      height: 100px;
      padding: unset;
      align-items: flex-start;
      padding-top: 15px;
    }

    @media (max-width: 400px) {
      height: 150px;
    }
  }

  .top-card-info-wrapper {
    display: flex;
    margin-left: 48px;

    .top-card-icon {
      display: flex;
      max-width: 20px;
      align-items: center;

      .tooltip-container {
        position: relative;
        display: inline-block;
      }

      .tooltip-text {
        visibility: hidden;
        width: 250px;
        background-color: #333;
        color: #fff;
        text-align: left;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        position: absolute;
        top: 175%;
        transform: translateX(-28%);
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 10;
        font-size: 14px;
      }

      .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
      }

      .tooltip-text ul {
        padding-left: 20px;
        margin: 10px 0 0;
      }

      .tooltip-text li {
        margin-bottom: 5px;
      }
    }

    .top-card-info {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      line-height: normal;
      color: #fff;
      justify-content: center;
      margin-bottom: 4px;

      @media (max-width: 768px) {
        margin-left: unset;
      }

      .description {
        font-size: 16px;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }

  .entities-data-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: end;
    padding-right: 38px;

    .container {
      display: flex;
      flex-direction: column;

      .description {
        color: #58c4da;
        text-align: right;
        font-size: 13px;
        font-weight: 400;
      }

      .value {
        color: #fff;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        text-align: right;
        padding-left: 10px;
      }
    }

    .tooltip-container {
      position: relative;
      display: inline-block;
    }

    .tooltip-text {
      visibility: hidden;
      width: 250px;
      background-color: #333;
      color: #fff;
      text-align: left;
      border-radius: 5px;
      padding: 10px 10px 5px 10px;
      position: absolute;
      top: 125%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 10;
      font-size: 14px;
    }

    .tooltip-container:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }

    .tooltip-text ul {
      padding-left: 20px;
      margin: 10px 0 0;
    }

    .tooltip-text li {
      margin-bottom: 5px;
    }
  }

  @media (max-width: 768px) {
    left: 0px;
    width: 100%;
    z-index: 0;
    padding-left: 0px;
  }
`

const BackWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  padding-right: 10rem;
`

const BackButton = styled.button`
  width: fit-content;
  height: fit-content;

  border: none;
  background-color: transparent;
  cursor: pointer;

  color: #58c4da;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.3px;
`

const DashboardTabsWrapper = styled.div`
  display: flex;
  background: ${colors.sebraeBlue};
  height: 42px;
  padding: 0 42px;
  align-items: flex-end;

  .active {
    background: #0f438a;
    color: #fff;
    font-size: 18px;
    line-height: 19px;
  }
`

const Tab = styled.div`
  display: flex;
  max-width: 220px;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 100%;
  padding: 8px 14px;
  margin: 0px 1px 0px 1px;

  @media (max-width: 900px) {
    padding: 0px;
  }

  color: #f0f6fe;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;

  border-radius: 12px 12px 0px 0px;
  cursor: pointer;

  .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .column-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-left: 20px; */
  }

  .column-icon {
    flex-direction: column;
    margin: auto;
  }

  :hover {
    background: rgba(15, 67, 138, 0.4);
    color: #fff;
    font-size: 18px;
    line-height: 19px;
    transition: 0.3s;
  }
`

export interface ContentProps {
  content: {
    height?: string
    display?: string
  }
}

export interface EntityDataProps {
  selectedEntities?: number
  availableEntities?: string
  outOfRangeTotal?: string
  suffix?: string
  definitionType?: string
  legalEntityActiveCount?: number
}

export default function TopCard({
  title,
  outOfRangeEntities,
  entityData,
  onToggleMenu,
  selectedLegalEntity,
  customStyles,
  dataSource,
  setDataSource,
  tabIndicators,
  isLoadingIndicatorsData,
  currentLevel,
  definedOption,
}: {
  title?: string
  outOfRangeEntities?: number | string
  entityData?: EntityDataProps
  onToggleMenu?: any
  selectedLegalEntity?: {
    selectedLegalEntityId: string
    selectedLegalEntityName: string
  }
  customStyles?: ContentProps
  dataSource?: string
  setDataSource?: React.Dispatch<React.SetStateAction<string>>
  tabIndicators?: any
  isLoadingIndicatorsData?: boolean
  currentLevel?: Level
  definedOption?: any
}) {
  const isMobile = useMedia('(max-width: 768px)')
  const isMobileLarge = useMedia('(max-width: 1024px)')
  const isTagsCondition = useMedia('(max-width: 1120px)')
  const [activeTab, setActiveTab] = useState(dataSource)
  const { context } = useAPI()

  const handleTabClick = (dataSource: string) => {
    if (setDataSource) {
      setDataSource(dataSource)
      setActiveTab(dataSource)
    }
  }

  const renderIndicatorIcon = (
    condition: boolean,
    percentage: number,
    upColor: string | undefined,
    downColor: string | undefined,
  ) => {
    if (isLoadingIndicatorsData) {
      return (
        <div style={{ display: 'flex' }}>
          <ClipLoader color={'#fff'} size={18} />
        </div>
      )
    } else if (isLoadingIndicatorsData === false && condition === true && percentage !== 0) {
      return (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <ArrowUpIcon height={18} width={18} fill={upColor} />
        </div>
      )
    } else if (isLoadingIndicatorsData === false && condition === false && percentage !== 0) {
      return (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <ArrowDownIcon height={18} width={18} fill={downColor} />
        </div>
      )
    } else {
      return null
    }
  }

  const renderEadIndicator = renderIndicatorIcon(
    tabIndicators?.ead.condition,
    tabIndicators?.ead.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const renderPortfolioIndicator = renderIndicatorIcon(
    tabIndicators?.portfolio.condition,
    tabIndicators?.portfolio.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const renderAppSebraeIndicator = renderIndicatorIcon(
    tabIndicators?.appSebrae.condition,
    tabIndicators?.appSebrae.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const [firstValue, secondValue] =
    entityData &&
    (entityData?.definitionType === 'legalEntityAttendanceIndex' ||
      entityData?.definitionType === 'legalEntityAttendance' ||
      entityData?.definitionType === 'legalEntity' ||
      entityData?.definitionType === 'customized') &&
    entityData.outOfRangeTotal
      ? entityData.outOfRangeTotal
          ?.split(',')
          .map(value => Number(value.trim().replace(/[\.,]/g, '')))
      : []

  return title ||
    outOfRangeEntities ||
    entityData?.selectedEntities ||
    onToggleMenu ||
    selectedLegalEntity ? (
    <Header>
      <div className='top-card' style={customStyles ? customStyles.content : undefined}>
        {entityData?.definitionType === undefined && definedOption === undefined && !isMobile ? (
          <div className='top-card-title'>
            <h1>{title}</h1>
          </div>
        ) : null}
        <div className='top-card-info-wrapper'>
          {entityData &&
          entityData?.definitionType &&
          entityData?.definitionType !== 'customized' &&
          entityData?.definitionType !== 'census' ? (
            <div className='top-card-icon'>
              <div className='tooltip-container'>
                <InfoIcon width={14} height={14} fill='#FFF' />
                <div className='tooltip-text'>
                  {entityData?.definitionType === 'legalEntityAttendanceIndex' ? (
                    <ul>
                      <li>
                        O índice de cobertura considera no <strong>numerador</strong> todas as
                        empresas, incluindo as ativas, inaptas, suspensas, extintas e outras
                        categorias, para contabilizar corretamente o número de atendimentos.
                      </li>
                      <li>
                        Enquanto o <strong>denominador</strong> inclui empresas ativas no mapa.
                      </li>
                    </ul>
                  ) : null}

                  {/* MEI */}
                  {entityData?.definitionType === 'legalEntity' ? (
                    <ul>
                      <li>
                        O índice atual considera como <strong>numerador</strong> todas as empresas
                        ativas com porte MEI no mapa.
                      </li>
                      <li>
                        Enquanto o <strong>denominador</strong> inclui empresas ativas no mapa.
                      </li>
                    </ul>
                  ) : null}

                  {entityData?.definitionType === 'legalEntityAttendance' ? (
                    <ul>
                      <li>
                        O valor do <strong>total da seleção</strong> considera todas as empresas,
                        incluindo as ativas, inaptas, suspensas, extintas e outras categorias, para
                        contabilizar corretamente o número de atendimentos.
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className='top-card-info'>
            {(entityData?.definitionType &&
              entityData?.definitionType === 'legalEntityAttendanceIndex') ||
            (definedOption?.type === 'legalEntityAttendanceIndex' && title) ? (
              <>
                <span className='description'>Índice de cobertura </span>
                <span className='title'>{title}</span>
              </>
            ) : null}
            {(entityData?.definitionType &&
              entityData?.definitionType === 'legalEntityAttendance') ||
            (definedOption?.type === 'legalEntityAttendance' && title) ? (
              <>
                <span className='description'>Empresas atendidas </span>
                <span className='title'>{title}</span>
              </>
            ) : null}

            {entityData?.definitionType &&
            (entityData?.definitionType === 'customized' ||
              entityData?.definitionType === 'legalEntity' ||
              entityData?.definitionType === 'census') ? (
              <>
                <span className='title' style={{ fontSize: '22px' }}>
                  {title}
                </span>
              </>
            ) : null}
          </div>
        </div>

        {!selectedLegalEntity && (
          <div className='entities-data-wrapper' style={{}}>
            {outOfRangeEntities !== 'Carregando...' &&
            entityData?.availableEntities &&
            currentLevel?.geo !== 'neighborhood' &&
            entityData?.outOfRangeTotal ? (
              <div className='tooltip-container'>
                <InfoIcon width={14} height={14} fill='#FFF' />
                <div className='tooltip-text'>
                  A seleção total inclui:
                  <ul style={{ paddingBottom: '10px' }}>
                    <li>
                      <strong>{entityData?.availableEntities.toLocaleString('pt-BR')}</strong>{' '}
                      empresas {entityData?.definitionType === 'customized' ? 'ativas' : null}{' '}
                      {entityData?.definitionType === 'legalEntityAttendance' ||
                      entityData?.definitionType === 'legalEntityAttendanceIndex'
                        ? 'atendidas'
                        : null}{' '}
                      disponíveis no mapa.
                    </li>
                    {Number(firstValue) > 0 &&
                    (entityData?.definitionType === 'legalEntityAttendance' ||
                      entityData?.definitionType === 'customized') ? (
                      <li>
                        <strong>{firstValue.toLocaleString('pt-BR')}</strong> empresas{' '}
                        {entityData?.definitionType === 'customized' ? 'ativas' : null}{' '}
                        {entityData?.definitionType === 'legalEntityAttendance'
                          ? 'atendidas'
                          : null}{' '}
                        indisponíveis no mapa por endereçamento limitado.
                      </li>
                    ) : null}
                    {Number(firstValue) > 0 &&
                    (entityData?.definitionType === 'legalEntityAttendanceIndex' ||
                      entityData?.definitionType === 'legalEntity') ? (
                      <li>
                        <strong>{firstValue.toLocaleString('pt-BR')}</strong> empresas atendidas
                        indisponíveis no mapa por endereçamento limitado.
                      </li>
                    ) : null}
                  </ul>
                  {Number(secondValue) > 0 &&
                  (entityData?.definitionType === 'legalEntityAttendanceIndex' ||
                    entityData?.definitionType === 'legalEntity') ? (
                    <>
                      A seleção total inclui também na contagem de seu denominador:
                      <ul>
                        <li>
                          <strong>
                            {entityData.legalEntityActiveCount?.toLocaleString('pt-BR')}
                          </strong>{' '}
                          empresas ativas disponiveis no mapa
                        </li>
                        <li>
                          <strong>{secondValue.toLocaleString('pt-BR')}</strong> empresas ativas
                          indisponíveis no mapa por endereçamento limitado.
                        </li>
                      </ul>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}

            {currentLevel?.geo === 'neighborhood' ? (
              <div className='tooltip-container'>
                <InfoIcon width={14} height={14} fill='#FFF' />
                <div className='tooltip-text'>
                  A contagem atual pode diferir do nível anterior. No nível de bairro, mostramos
                  empresas específicas do bairro, enquanto no nível de ruas, consideramos ruas que
                  podem cruzar outros bairros, aumentando o total.
                </div>
              </div>
            ) : null}

            {entityData && (
              <div className='container' style={{}}>
                <span className='description'>Total da seleção</span>
                <span className='value'>
                  {entityData?.outOfRangeTotal === 'Carregando...' ||
                  entityData?.selectedEntities === undefined ||
                  Number.isNaN(Number(entityData?.selectedEntities))
                    ? 'Carregando...'
                    : `${
                        entityData.definitionType === 'census'
                          ? entityData.availableEntities
                          : entityData.selectedEntities?.toLocaleString('pt-BR')
                      } ${entityData?.suffix}`}
                </span>
              </div>
            )}
          </div>
        )}

        {selectedLegalEntity && (
          <BackWrapper>
            <BackButton onClick={onToggleMenu}>{'<'} Voltar a página anterior</BackButton>
          </BackWrapper>
        )}
      </div>

      {location.pathname === '/app' && !isMobile && (
        <DashboardTabsWrapper>
          {!context.user?.roles.includes('appSebraeManager') ? (
            <Tab
              style={activeTab === 'Geral' ? { backgroundColor: '#0f438a' } : {}}
              className={activeTab === 'Geral' ? 'active' : ''}
              onClick={() => handleTabClick('Geral')}
            >
              Geral
            </Tab>
          ) : null}
          <Tab
            style={
              activeTab === 'SEBRAE Na Sua Empresa (App)' ? { backgroundColor: '#0f438a' } : {}
            }
            className={
              activeTab === 'SEBRAE Na Sua Empresa (App)' ||
              context.user?.roles.includes('appSebraeManager')
                ? 'active'
                : ''
            }
            onClick={() => handleTabClick('SEBRAE Na Sua Empresa (App)')}
          >
            <div className='row'>
              <div className='column-text'>SEBRAE Na Sua Empresa (App)</div>
              <div className='column-icon'>
                {!context.user?.roles.includes('appSebraeManager') ? renderAppSebraeIndicator : ''}
              </div>
            </div>
          </Tab>
          {!context.user?.roles.includes('appSebraeManager') ? (
            <Tab
              style={activeTab === 'Portfólio Ead' ? { backgroundColor: '#0f438a' } : {}}
              className={activeTab === 'Portfólio Ead' ? 'active' : ''}
              onClick={() => handleTabClick('Portfólio Ead')}
            >
              <div className='row'>
                <div className='column-text'>Portfólio EaD</div>
                <div className='column-icon'>{renderPortfolioIndicator}</div>
              </div>
            </Tab>
          ) : null}
          {!context.user?.roles.includes('appSebraeManager') ? (
            <Tab
              style={activeTab === 'EaD' ? { backgroundColor: '#0f438a' } : {}}
              className={activeTab === 'EaD' ? 'active' : ''}
              onClick={() => handleTabClick('EaD')}
            >
              <div className='row'>
                <div className='column-text'>EaD</div>
                <div className='column-icon'>{renderEadIndicator}</div>
              </div>
            </Tab>
          ) : null}
        </DashboardTabsWrapper>
      )}
    </Header>
  ) : null
}
