import React from 'react'
import Handle from '~/prix/react/components/handle'
import { formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import OriginalLink from '~/prix/react/components/link'
import styled from 'styled-components'
import courseProductMostUsedQuery from '../queries/courseProductMostUsed.query'
import useItems from '~/prix/react/hooks/items'
import RedirectIcon from '~/components/icons/arrows/16px_curved-next.svg'

interface DashboardGridItemMostUsedCourseProductListProps {
  id: number
  name: string
  total_register: number
  total_legal_entity: number
}

const Items = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
  padding: 4px 12px 0px 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  min-height: 119px;
`

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid #b6bfc8;
  padding: 4px 0px 4px 0px;
`

const Header = styled.div`
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #576370;
  }

  padding: 8px 2px 8px 6px;
`

const ColumnData = styled.div`
  padding: 4px;
`

export default function DashboardGridItemMostCourseProductList({
  userGeoStateAbbreviation,
  dataSourceId,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
}) {
  const listQuery = useItems(
    () =>
      courseProductMostUsedQuery({
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
      }),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  const formatStringForUrl = (inputString: string) => {
    return inputString.replace(/ /g, '+')
  }

  const ListItem = ({
    id,
    name,
    total_register,
    total_legal_entity,
  }: DashboardGridItemMostUsedCourseProductListProps) => (
    <ListRow>
      <ColumnData style={{ width: '45%' }}>{name}</ColumnData>
      <ColumnData style={{ width: '20%', textAlign: 'center' }}>
        {formatAsBrIntegerNumber(total_register)}
      </ColumnData>
      <ColumnData style={{ width: '25%', textAlign: 'center' }}>
        {formatAsBrIntegerNumber(total_legal_entity)}
      </ColumnData>
      <ColumnData style={{ width: '10%' }}>
        <a
          href={`/app/map/country/30?filterType=attendance&filterName=${formatStringForUrl(
            name,
          )}&filterValue=lastYear&filterAttendanceSource=5&filterEadOption=individual&filterCourseProduct=${id}&definedOption=customized`}
          className='link'
          title='Acessar'
        >
          <RedirectIcon fill={'#0F438A'} width={18} height={18} />
        </a>
      </ColumnData>
    </ListRow>
  )

  const currentData = listQuery.items?.map((item: any) => ({
    id: item.course_product,
    name: item.course_product_name,
    total_register: item.total,
    total_legal_entity: item.totalLegalEntity,
  }))

  return (
    <Handle isLoading={listQuery.isLoading} error={listQuery.error} style={{ minHeight: '100px' }}>
      <Items>
        <ListRow>
          <Header style={{ width: '45%' }}>
            <span>Curso</span>
          </Header>
          <Header style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
            <span>Matriculas</span>
          </Header>
          <Header style={{ width: '25%', textAlign: 'center' }}>
            <span>Empresas ativas disponíveis no mapa</span>
          </Header>
          <Header style={{ width: '10%' }}></Header>
        </ListRow>
        {currentData?.map((item, index) => (
          <ListItem key={index} {...item} />
        ))}
      </Items>
    </Handle>
  )
}
