import React, { useCallback, useEffect, useState } from 'react'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import TopCard from '~/components/topCard'
import { colors } from '~/design'
import {
  ascending,
  boolean,
  distinct,
  entity,
  enumerated,
  equals,
  formatAsBrNumber,
  isNull,
  like,
  notEquals,
  query,
  string,
  truthy,
} from '~/prix'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px 8px;

    fieldset.section-container {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding: 15px 15px 30px 15px;
      margin-bottom: 10px;
      border: solid 1px rgba(221, 221, 221, 1);
      border-radius: 0px 0px 12px 12px;
      box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);

      .item-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 13px;

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
          background-color: #fff;
          width: fit-content;
          padding: 0 2px;
        }

        &.results-container {
          margin-top: 0;
          margin-bottom: 10px;

          label {
            margin-bottom: 0;
            margin-left: 0;
            padding: 0;
          }

          output {
            margin-top: 2.5px;
          }
        }

        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
            margin-bottom: 7.5px;
          }
        }
      }
      .item-additional-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        small {
          margin-top: 2.5px;
        }

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
          margin-top: 10px;
        }
        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
          }
        }
      }
    }

    .line-container {
      margin: 5px 0px;
    }

    .line-container-label {
      width: 56px;
    }

    .line {
      display: block;
      height: 1px;
      background: transparent;
      width: 100%;
      border: none;
      margin-top: 5px;
      margin-bottom: 5px;
      border-top: solid 1px rgba(221, 221, 221, 1);
    }

    .legend-container {
      margin: 16px 16px 0;
      height: 120px;

      label {
        color: ${colors.sebraeBlue};
        font-weight: normal;
      }

      div {
        display: flex;

        .column-background {
          width: 25px;
          flex-direction: column;
          align-items: left;
          margin-right: 10px;
        }

        .column {
          flex: 1;
          flex-direction: column;
          height: 65px;
        }

        .row {
          flex-direction: row;
          align-items: center;
          padding: 5px;

          .row-span {
            font-size: 14px;
            color: #363636;
            font-weight: normal;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .alert-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: 16px 8px 10px 8px;
    color: #212529;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    padding: 0;

    .containers {
      margin-top: -32px;
      padding: 0 32px;
      z-index: 5;
    }

    .alert-info,
    .legend-container {
      padding: 0 32px;
    }
  }
`

const SectionTitle = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--p-action, #0f438a);
  display: flex;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  margin: 0 2px;

  span {
    color: #fff;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }
`

const MenuSection = styled.p`
  color: var(--p-default, #005eb8);
  font-size: 16px;
  line-height: 23.3px;
  font-weight: 700;
  margin: 0;
`

interface AttendantMapMenu {
  onChangeValues: any
  title: string
  value: number
  isLoading: boolean
  toggleMenuVisibility: () => void
  individualAttendant?: any
  handleEntityData: (setEntityData: any) => void
}

export default function AttendantMapMenu({
  onChangeValues,
  title,
  value,
  isLoading,
  toggleMenuVisibility,
  individualAttendant,
  handleEntityData,
}: AttendantMapMenu) {
  const [attendantSelected, setAttendantSelected] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [dataSourceSelected, setDataSourceSelected] = useState<
    { id: string; name: string } | undefined
  >(undefined)
  const [periodSelected, setPeriodSelected] = useState<{ value: string } | undefined>(undefined)
  const isMobile = useMedia('(max-width: 768px)')

  useEffect(() => {
    if (attendantSelected?.id || dataSourceSelected?.id || periodSelected?.value) {
      onChangeValues({
        attendant: { id: attendantSelected?.id, name: attendantSelected?.name },
        dataSource: { id: dataSourceSelected?.id, name: dataSourceSelected?.name },
        period: { value: periodSelected?.value },
      })
    }

    if (individualAttendant) {
      onChangeValues({
        attendant: { id: individualAttendant?.cpf, name: individualAttendant?.name },
        dataSource: { id: dataSourceSelected?.id, name: dataSourceSelected?.name },
        period: { value: periodSelected?.value },
      })
    }

    if (attendantSelected?.id === undefined && individualAttendant === undefined) {
      setAttendantSelected(undefined)
      setDataSourceSelected(undefined)
      setPeriodSelected(undefined)

      onChangeValues({
        attendant: { id: undefined, name: undefined },
        dataSource: { id: undefined, name: undefined },
        period: { value: undefined },
      })
    }
  }, [attendantSelected, dataSourceSelected, periodSelected, individualAttendant])

  const attendantListQueryFactory = useCallback(
    (input: string) =>
      query('legalEntityAttendance')
        .select({
          agentCpf: distinct(entity('legalEntityAttendance').property('agentCpf')),
        })
        .join({
          current: entity('legalEntityAttendance').property('legalEntityId'),
          target: entity('legalEntity').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            isNull(entity('legalEntity').property('deletedAt')),
            like(
              entity('legalEntityAttendance').property('agentCpf'),
              string().value(`%${input.replace(/\s/g, '%')}%`),
            ),
          ].filter(truthy),
        )
        .order(ascending('agentCpf'))
        .limit(10),
    [],
  )

  const dataSourceListQueryFactory = useCallback(
    () =>
      query('dataSource')
        .select({
          id: entity('dataSource').property('id'),
          name: entity('dataSource').property('name'),
        })
        .where(
          ...[
            notEquals(entity('dataSource').property('id'), string().value('5')),
            equals(entity('dataSource').property('isActive'), boolean().value(true)),
          ],
        )
        .order(ascending('id'))
        .limit(10),
    [],
  )

  const period = {
    lastMonth: 'Último mês',
    lastYear: 'Último ano',
  }

  const handleOption = useCallback((type, valueId, valueName) => {
    if (type === 'attendant' && valueId && valueName) {
      setAttendantSelected({ id: valueId, name: valueName })
    } else if (type === 'attendant' && !valueId) {
      setAttendantSelected(undefined)
    }

    if (type === 'dataSource' && valueId && valueName) {
      setDataSourceSelected({ id: valueId, name: valueName })
    } else if (type === 'dataSource' && !valueId) {
      setDataSourceSelected(undefined)
    }

    if (type === 'period' && valueId) {
      setPeriodSelected({ value: valueId })
    } else if (type === 'period' && !valueId) {
      setPeriodSelected(undefined)
    }
  }, [])

  return (
    <>
      <Wrapper>
        {isMobile && <TopCard title='Mapa de atendimentos' />}
        <div className='containers'>
          <SectionTitle>
            <span>Filtros</span>
          </SectionTitle>
          <fieldset className='section-container'>
            <MenuSection>Encontrar atendimentos</MenuSection>
            <div className='item-container'>
              <label>Atendente</label>
              <div style={{ marginTop: '4px' }}>
                <SelectFromQuery
                  queryFactory={attendantListQueryFactory}
                  placeholder='Selecione...'
                  idProperty='id'
                  labelProperty='agentCpf'
                  isDisabled={individualAttendant !== undefined}
                  value={
                    attendantSelected?.id && individualAttendant === undefined
                      ? { id: attendantSelected.id, agentCpf: attendantSelected.name }
                      : individualAttendant !== undefined
                      ? { id: individualAttendant.cpf, agentCpf: individualAttendant.cpf }
                      : undefined
                  }
                  onDirectChange={value =>
                    handleOption('attendant', value?.agentCpf, value?.agentCpf)
                  }
                />
              </div>
            </div>

            <div className='item-container'>
              <div style={{ marginTop: '8px' }} />
              <label>Fonte de dados</label>
              <div style={{ marginTop: '4px' }}>
                <SelectFromQuery
                  queryFactory={dataSourceListQueryFactory}
                  placeholder='Selecione...'
                  idProperty='id'
                  labelProperty='name'
                  isDisabled={!attendantSelected?.id && !individualAttendant}
                  value={
                    dataSourceSelected?.id
                      ? { id: dataSourceSelected.id, name: dataSourceSelected.name }
                      : undefined
                  }
                  onDirectChange={value => handleOption('dataSource', value?.id, value?.name)}
                />
              </div>
            </div>

            <div className='item-container'>
              <div style={{ marginTop: '8px' }} />
              <label>Período</label>
              <div style={{ marginTop: '4px' }}>
                <EnumeratedField
                  fieldSchema={enumerated(period).isOptional()}
                  value={periodSelected?.value}
                  meta={{
                    isDisabled:
                      !attendantSelected?.id && individualAttendant === undefined ? true : false,
                  }}
                  onDirectChange={(value: any) => handleOption('period', value, undefined)}
                />
              </div>
            </div>

            <div className='item-container results-container'>
              {isMobile ? (
                <>
                  <div className='line-container'>
                    <hr className='line' />
                  </div>
                  <label>Total da seleção</label>
                  <output>
                    {isLoading === true ? (
                      <small>Carregando...</small>
                    ) : value > 0 ? (
                      `${formatAsBrNumber(value)} atendimentos`
                    ) : (
                      'Sem atendimentos'
                    )}
                  </output>
                </>
              ) : (
                handleEntityData(
                  isLoading === true
                    ? 'Carregando...'
                    : value > 0
                    ? `${formatAsBrNumber(value)} atendimentos`
                    : 'Sem atendimentos',
                )
              )}
            </div>
          </fieldset>
        </div>

        <div style={{ flex: 1 }}></div>
      </Wrapper>
    </>
  )
}
