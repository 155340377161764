import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { contrastGradientColor } from '~/design'
import countCityCensusEntitiesByArea from '~/packages/cityIbgeCensus/cityCensusByArea/countCityCensusByArea.query'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoItem,
  GeoLegalEntitiesPossibilities,
  geoLevelToGeoprocessingColumn,
  geoQueries,
  Level,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { QueryBase } from '~/prix/query'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useItems from '~/prix/react/hooks/items'
import { GeoJsonMultiPolygon, GeoJsonPoint } from '~/prix/types/geoJson'
import { DefinedOption } from './menu/legalEntityGeoprocessingMapMenu.data'
import countCityCensusByPopulation from '~/packages/cityIbgeCensus/cityCensusByPopulation/countCityCensusByPopulation.query'
import { formatAsPercentage } from '~/prix'
import legalEntityProfitsQuery from '../../legalEntityProfit/legalEntityProfits.query'
import { census, profits, scores } from './menu/legalEntityGeoprocessingCustomizedMapMenu.component'

interface DefinedQueryParams {
  groupColumn: string
  idColumn: any
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
}

export default function useLegalEntityGeoprocessingPolygonsMap({
  childrenGeoLevel,
  id,
  by,
  filter,
  highlight,
  isEnabled,
  definedOption,
}: {
  childrenGeoLevel:
    | 'macroRegions'
    | 'states'
    | 'mesoRegions'
    | 'microRegions'
    | 'cities'
    | 'neighborhoods'
  by:
    | 'countryId'
    | 'countryIsoCode'
    | 'macroRegionId'
    | 'mesoRegionId'
    | 'stateId'
    | 'microRegionId'
    | 'cityId'
    | 'neighborhoodId'
    | null
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  isEnabled: boolean
  definedOption: DefinedOption | null
}) {
  const { google } = useGoogleMaps()
  const [items, setItems] = useState<Array<GeoItem> | null>(null)
  const params = useParams()
  const [level, setLevel] = useState<Level | null>(null)

  const geoResult = useItems(
    () =>
      isEnabled
        ? ((geoQueries[childrenGeoLevel] as any)(by, id!) as ReturnType<
            (typeof geoQueries)['cities']
          >)
        : (null as never),
    [childrenGeoLevel, id, by, isEnabled],
    { cache: 60 * 60 * 12, autoLoad: isEnabled },
  )

  const isHighlightMode =
    (highlight?.type &&
      highlight?.value !== undefined &&
      highlight?.value !== null &&
      highlight?.value !== '') ||
    definedOption?.type === 'legalEntityAttendanceIndex'

  const definedQuery: ((params: DefinedQueryParams) => any) | null = definedOption
    ? definedOption.query
    : null

  const isHighlightArea =
    highlight && highlight?.type === 'area' && highlight?.value === 'censusUpdated'
  const isHighlightPerCapta =
    highlight && highlight?.type === 'perCapta' && highlight?.value === 'censusUpdated'

  const isHighlightCensus =
    highlight && census.includes(highlight?.type) && highlight?.value === 'censusUpdated'

  const isHighlightProfits =
    highlight && profits.includes(highlight?.type) && highlight?.value === 'profitsData'

  const isHighlightScores =
    highlight && scores.includes(highlight?.type) && highlight?.value === 'profitsData'

  const isHighlightIndicators = isHighlightProfits || isHighlightScores
  const isHighlightGenericType = !isHighlightCensus && !isHighlightProfits && !isHighlightScores

  const legalEntitiesResult = useItems(
    () =>
      isEnabled && definedQuery
        ? definedQuery({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            idColumn: by,
            id,
            filter,
            highlight,
          })
        : (null as never),
    [childrenGeoLevel, by, id, filter, highlight, isEnabled, definedQuery, level],
    {
      cache: 60 * 60 * 24,
      autoLoad: isEnabled && definedQuery !== null,
    },
  )

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: '30',
      childrenGeoLevel: params.childrenGeoLevel as GeoChildrenLegalEntitiesPossibilities,
    })
  }, [params])

  const resultArea = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusEntitiesByArea({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled && highlight?.type === 'area' && highlight?.value === 'censusUpdated',
    },
  )

  const resultPopulation = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusByPopulation({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [childrenGeoLevel, level, level?.id!, highlight?.value, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled && highlight?.value === 'censusUpdated',
    },
  )

  const resultProfits = useItems(
    () =>
      level && isEnabled && highlight && highlight?.type
        ? (legalEntityProfitsQuery({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            level: level?.geo,
            id: level?.id!,
            highlight: { type: highlight?.type, value: highlight?.value },
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, isEnabled, highlight, highlight?.value],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled && highlight?.value === 'profitsData',
    },
  )

  const filterScores = resultProfits.items?.filter(item => Number(item.count) >= 5)

  const mergeCensusPerCapta = useMemo(() => {
    const merge =
      legalEntitiesResult.items && resultPopulation.items && isHighlightPerCapta
        ? legalEntitiesResult.items.map(values => ({
            ...values,
            ...resultPopulation.items?.find(items => values.geoId === items.geoId),
          }))
        : null

    const result = merge?.map(item => ({
      geoId: item.geoId,
      count: item.highlight ? Number(item.highlight) : -Infinity, // valor da população
      highlight: item.count ? Number(item.count) : -Infinity, // valor de empresas
      lastUpdated: item.lastUpdated !== undefined ? Number(item.lastUpdated) : null,
    }))

    return result
  }, [legalEntitiesResult.items, resultPopulation.items, isHighlightPerCapta])

  const mergeCensusArea = useMemo(() => {
    const merge =
      legalEntitiesResult.items && resultArea.items && isHighlightArea
        ? legalEntitiesResult.items.map(values => ({
            ...values,
            ...resultArea.items?.find(items => values.geoId === items.geoId),
          }))
        : null

    const result = merge?.map(item => ({
      geoId: item.geoId,
      count: item.highlight ? Number(item.highlight) : -Infinity, // valor da área
      highlight: item.count ? Number(item.count) : -Infinity, // valor de empresas
      lastUpdated: item.lastUpdated ? Number(item.lastUpdated) : null,
    }))

    return result
  }, [legalEntitiesResult.items, resultArea.items, isHighlightArea])

  const mergeProfits = useMemo(() => {
    const merge =
      legalEntitiesResult.items && resultProfits.items && isHighlightIndicators
        ? legalEntitiesResult.items.map(values => ({
            ...values,
            ...filterScores?.find(items => values.geoId === items.geoId),
          }))
        : null

    const result = merge?.map(item => ({
      geoId: item.geoId,
      count: item.count,
      highlight: item.highlight ? Number(item.highlight) : null,
    }))

    return result
  }, [legalEntitiesResult.items, resultProfits.items, isHighlightIndicators])

  const maxValue = useMemo(() => {
    if (legalEntitiesResult.items && !isHighlightCensus && !isHighlightMode) {
      return Math.max(
        0,
        ...legalEntitiesResult.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.count)
          }
          return 0
        }),
      )
    }
    if (isHighlightIndicators && resultProfits.items && !isHighlightCensus && mergeProfits) {
      const result = Math.max(
        0,
        ...mergeProfits
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => Number(item.highlight)),
      )

      return result
    }

    if (legalEntitiesResult.items && isHighlightMode && isHighlightGenericType) {
      return Math.max(
        0,
        ...legalEntitiesResult.items
          .filter((item, index) => index !== legalEntitiesResult.items.length - 1)
          .map(item => {
            if (typeof item.count === 'number' && item.highlight) {
              return Number(item.highlight) / item.count
            }
            return 0
          }),
      )
    }

    if (resultArea.items && isHighlightArea && mergeCensusArea) {
      return Math.max(
        0,
        ...mergeCensusArea
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => {
            if (typeof item.count === 'number' && typeof item.highlight === 'number') {
              return Number(item.highlight) / Number(item.count)
            }
            return Infinity
          }),
      )
    }

    if (resultPopulation.items && isHighlightPerCapta && mergeCensusPerCapta) {
      const result = Math.max(
        0,
        ...mergeCensusPerCapta
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => Number(item.highlight) / Number(item.count)),
      )

      return result
    }

    if (!legalEntitiesResult.items) {
      return -Infinity
    }
  }, [
    legalEntitiesResult.items,
    mergeCensusArea,
    isHighlightArea,
    mergeCensusPerCapta,
    isHighlightPerCapta,
    mergeProfits,
    isHighlightGenericType,
    isHighlightIndicators,
    isHighlightCensus,
    level,
  ])

  const minValue = useMemo(() => {
    if (
      legalEntitiesResult.items &&
      isHighlightMode &&
      isHighlightGenericType &&
      !isHighlightIndicators
    ) {
      return Math.min(
        Infinity,
        ...legalEntitiesResult.items.map(item => {
          if (typeof item.highlight === 'number' && item.geoId !== null) {
            return item.highlight / Number(item.count)
          }
          return Infinity
        }),
      )
    }

    if (legalEntitiesResult.items && isHighlightGenericType && !isHighlightIndicators) {
      return Math.min(
        Infinity,
        ...legalEntitiesResult.items.map(item => {
          if (typeof item.count === 'number' && item.geoId !== null) {
            return Number(item.count)
          }
          return Infinity
        }),
      )
    }

    if (mergeProfits && isHighlightIndicators) {
      const result = Math.min(
        Infinity,
        ...mergeProfits
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => {
            if (typeof item.highlight === 'number') {
              return Number(item.highlight)
            }
            return Infinity
          }),
      )
      return result
    }

    if (resultArea.items && isHighlightArea && mergeCensusArea) {
      return Math.min(
        Infinity,
        ...mergeCensusArea
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => {
            if (typeof item.count === 'number' && typeof item.highlight === 'number') {
              return Number(item.highlight) / Number(item.count)
            }
            return Infinity
          }),
      )
    }

    if (resultPopulation.items && isHighlightPerCapta && mergeCensusPerCapta) {
      const result = Math.min(
        Infinity,
        ...mergeCensusPerCapta
          .filter(item => item.highlight !== undefined && item.geoId !== null)
          .map(item => {
            if (typeof item.count === 'number' && typeof item.highlight === 'number') {
              return Number(item.highlight) / Number(item.count)
            }
            return Infinity
          }),
      )
      return result
    }

    if (!legalEntitiesResult.items) {
      return Infinity
    }

    return -Infinity
  }, [
    legalEntitiesResult.items,
    mergeCensusArea,
    isHighlightArea,
    isHighlightPerCapta,
    mergeCensusPerCapta,
    mergeProfits,
    isHighlightGenericType,
    isHighlightIndicators,
    level,
  ])

  const legalEntitiesGeoprocessingMap = useMemo(() => {
    const items = isHighlightIndicators
      ? mergeProfits
      : isHighlightArea
      ? mergeCensusArea
      : isHighlightPerCapta
      ? mergeCensusPerCapta
      : legalEntitiesResult?.items
    if (!items) {
      return null
    }

    type Item = (typeof items)[0]

    return items.reduce((acc, next) => {
      acc.set(next.geoId as string | number, next)
      return acc
    }, new Map<string | number, Item>())
  }, [
    legalEntitiesResult?.items,
    isHighlightArea,
    isHighlightIndicators,
    isHighlightPerCapta,
    mergeProfits,
    mergeCensusArea,
    mergeCensusPerCapta,
  ])

  const bounds = useMemo(() => {
    const items = geoResult.items
    if (!items || !isEnabled) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const multiPolygon = item.boundary as GeoJsonMultiPolygon
      const point = item.center as GeoJsonPoint

      if (multiPolygon && multiPolygon.type === 'MultiPolygon') {
        for (const boundary of multiPolygon.coordinates) {
          const coordinates = boundary[0]
          for (const [longitude, latitude] of coordinates) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }
    return {
      south,
      west,
      north,
      east,
    }
  }, [geoResult.items, isEnabled])
  const googleBounds = useMemo(() => {
    if (!google || !bounds) {
      return null
    }
    const southWest = new google.maps.LatLng(bounds.south, bounds.west)
    const northEast = new google.maps.LatLng(bounds.north, bounds.east)
    return new google.maps.LatLngBounds(southWest, northEast)
  }, [google, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  useEffect(() => {
    const geoItems = geoResult?.items
    if (
      !isEnabled ||
      !google ||
      !geoItems ||
      !legalEntitiesGeoprocessingMap ||
      legalEntitiesResult.isLoading ||
      resultPopulation.isLoading ||
      resultArea.isLoading ||
      resultProfits.isLoading ||
      geoResult.isLoading ||
      !isFinite(maxValue)
    ) {
      return
    }

    setItems(
      geoItems.map(geoItem => {
        const legalEntity = legalEntitiesGeoprocessingMap.get(geoItem.id as string)
        const countValue = legalEntity?.count ?? 0

        const highlightValue = isHighlightMode ? ((legalEntity?.highlight || 0) as number) : null
        const isCensusHighlighted = isHighlightCensus && highlightValue !== null

        const centerGeoJson = geoItem.center as { coordinates: number[] } | null

        const percentColorHighlightValue =
          maxValue &&
          highlightValue !== null &&
          !isHighlightIndicators &&
          !isHighlightProfits &&
          !isHighlightScores
            ? highlightValue / Number(countValue) / maxValue
            : null
        const percentColorCountValue =
          maxValue &&
          maxValue !== 0 &&
          highlightValue === null &&
          !isHighlightProfits &&
          !isHighlightScores
            ? Number(countValue) / maxValue
            : null
        const percentColorProfits =
          highlightValue !== null && maxValue && maxValue !== 0 && isHighlightProfits
            ? highlightValue / maxValue
            : null

        const percentColorScores =
          highlightValue !== null && maxValue && maxValue !== 0 && isHighlightScores
            ? highlightValue / 5
            : null
        const percentColor = percentColorHighlightValue
          ? percentColorHighlightValue
          : percentColorCountValue
          ? percentColorCountValue
          : percentColorProfits
          ? percentColorProfits
          : percentColorScores
          ? percentColorScores
          : 0

        const color = contrastGradientColor(percentColor)

        const percentColorForIndex =
          highlightValue && maxValue ? highlightValue / Number(countValue) / maxValue : 0

        const maxLegendValue =
          maxValue && isHighlightMode && isHighlightGenericType
            ? `${formatAsPercentage(maxValue)}`
            : maxValue

        const minLegendValue =
          (minValue && isHighlightMode && isHighlightGenericType) ||
          (minValue === 0 && isHighlightMode && isHighlightGenericType)
            ? `${formatAsPercentage(minValue)}`
            : minValue

        const updateYearCensus = isCensusHighlighted ? legalEntity?.lastUpdated : null

        return {
          id: geoItem.id,
          key: geoItem.id,
          name: geoItem.name,
          multiPolygon: geoItem.boundary,
          path: null,
          center: centerGeoJson
            ? new google.maps.LatLng(centerGeoJson.coordinates[1], centerGeoJson.coordinates[0])
            : null,
          color: color.hex('rgb'),
          percentColor:
            definedOption?.type !== 'legalEntityAttendanceIndex' && !isCensusHighlighted
              ? percentColor
              : percentColorForIndex,
          borderColor: color.darken(0.8).saturate(0.2).hex('rgb'),
          count: countValue,
          highlight: highlightValue,
          levelKey: childrenGeoLevel,
          maxValue: maxValue,
          legendValues: [
            {
              maxValue: maxLegendValue,
              minValue: minLegendValue,
            },
          ],
          updateYearCensus: updateYearCensus,
        }
      }) as unknown as typeof items,
    )
  }, [
    legalEntitiesGeoprocessingMap,
    geoResult?.items,
    geoResult.isLoading,
    legalEntitiesResult.isLoading,
    resultPopulation.isLoading,
    resultArea.isLoading,
    resultProfits.isLoading,
    google,
    maxValue,
    minValue,
    isEnabled,
    isHighlightIndicators,
    isHighlightProfits,
    isHighlightScores,
  ])

  const isLoadingAll =
    geoResult.isLoading ||
    legalEntitiesResult.isLoading ||
    resultPopulation.isLoading ||
    resultArea.isLoading ||
    resultProfits.isLoading

  return {
    items: isEnabled && definedOption ? items : null,
    bounds: googleBounds,
    isLoading: isLoadingAll,
    error: geoResult.error || legalEntitiesResult.error,
    queries: isEnabled && definedOption ? [legalEntitiesResult.query, geoResult.query] : [],
  }
}
