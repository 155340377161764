import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  Level,
} from '../legalEntityGeoprocessingMapLevels.data'
import useItems from '~/prix/react/hooks/items'
import additionalInformationQuery from './additionalInformation.query'
import { formatAsBrNumber } from '~/prix/utils/types/number'
import { QueryBase } from '~/prix/query'
import countCityCensusByPopulation from '~/packages/cityIbgeCensus/cityCensusByPopulation/countCityCensusByPopulation.query'
import countCityCensusEntitiesByArea from '~/packages/cityIbgeCensus/cityCensusByArea/countCityCensusByArea.query'
import useMedia from 'react-use/lib/useMedia'

export default function useAdditionalInformation(
  isLoadingMainTotal: boolean,
  handleAdditionalInfo: (value: string | null) => void,
  handleAdditionalInfoIsLoading: (value: boolean) => void,
) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [level, setLevel] = useState<Level | null>(null)
  const [isEnabled, setIsEnabled] = useState(false)
  const temporaryCountryId = '30'
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useMedia('(max-width: 768px)')

  const isAreaCensus =
    searchParams.get('highlightType') === 'area' &&
    searchParams.get('highlightValue') === 'censusUpdated'

  const isPopulationCensus =
    searchParams.get('highlightType') === 'perCapta' &&
    searchParams.get('highlightValue') === 'censusUpdated'

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
    } else {
      setLevel({
        geo: 'country',
        id: temporaryCountryId,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
    }
  }, [params])

  const definedOption = searchParams.get('definedOption')

  const filter = useMemo(() => {
    let definedOptionDataSource

    if (
      definedOption === 'legalEntityAttendanceAppSebraeQuery' ||
      definedOption === 'legalEntityAttendanceAppSebraeIndex'
    ) {
      definedOptionDataSource = 6
    }

    if (
      definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
      definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
      definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
      definedOption === 'legalEntityAttendancePortfolioEadIndexQuery'
    ) {
      definedOptionDataSource = 5
    }

    if (
      definedOption === 'legalEntityAttendanceRadarAliIndex' ||
      definedOption === 'legalEntityAttendanceRadarAli'
    ) {
      definedOptionDataSource = 3
    }

    const filterType = searchParams.get('filterType')
    const filterName = searchParams.get('filterName')
    const filterValue = searchParams.get('filterValue')
    const filterAttendanceSource = searchParams.get('filterAttendanceSource')
    return filterType
      ? ({
          type: filterType,
          name: filterName,
          value: filterValue,
          attendanceSource: filterAttendanceSource
            ? filterAttendanceSource
            : definedOption && definedOptionDataSource
            ? definedOptionDataSource
            : null,
          definedOption,
        } as FilterHighlight)
      : null
  }, [searchParams, definedOption])

  const highlight = useMemo(() => {
    const highlightType = searchParams.get('highlightType')
    const highlightName = searchParams.get('highlightName')
    const highlightValue = searchParams.get('highlightValue')
    const highlightAttendanceSource = searchParams.get('highlightAttendanceSource')
    return highlightType
      ? ({
          type: highlightType,
          name: highlightName,
          value: highlightValue,
          attendanceSource: highlightAttendanceSource,
          definedOption,
        } as FilterHighlight)
      : null
  }, [searchParams, definedOption])

  const loaderControl = useMemo(() => {
    if (definedOption === 'customized') {
      return isEnabled
    }

    if (definedOption === 'legalEntityMeiPercentage') {
      return isEnabled
    }

    return isEnabled && filter !== undefined && filter !== null
  }, [definedOption, isEnabled, filter])

  const additionalInformationsResult = useItems(
    () =>
      level && loaderControl
        ? (additionalInformationQuery(
            level?.geo,
            level?.id!,
            filter,
            highlight,
            definedOption,
          ) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, highlight, loaderControl, definedOption],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: loaderControl,
    },
  )

  const indexOptions = [
    'legalEntityAttendanceAppSebraeIndex',
    'legalEntityAttendanceAllCoursesEadIndexQuery',
    'legalEntityAttendancePortfolioEadIndexQuery',
    'legalEntityAttendanceRadarAliIndex',
  ]

  const additionalInformationsLegalEntityActiveResult = useItems(
    () =>
      level && loaderControl && definedOption
        ? (additionalInformationQuery(
            level?.geo,
            level?.id!,
            null,
            null,
            'customized',
          ) as QueryBase)
        : (null as never),
    [level, level?.id!, loaderControl, definedOption],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: loaderControl,
    },
  )

  const resultPopulation = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusByPopulation({
            groupColumn: 'cityId',
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const resultArea = useItems(
    () =>
      level && isEnabled
        ? (countCityCensusEntitiesByArea({
            groupColumn: 'cityId',
            level: level?.geo,
            id: level?.id!,
          }) as QueryBase)
        : (null as never),
    [level, level?.id!, filter, isEnabled],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled,
    },
  )

  const mergePopulation = useMemo(
    () =>
      resultPopulation.items && additionalInformationsResult.items && isPopulationCensus
        ? resultPopulation.items.map(values => ({
            ...values,
            ...additionalInformationsResult.items?.find(items => values.geoId === items.cityId),
          }))
        : null,
    [resultPopulation.items, additionalInformationsResult.items, isPopulationCensus],
  )

  const mergeArea = useMemo(
    () =>
      resultArea.items && additionalInformationsResult.items && isAreaCensus
        ? resultArea.items.map(values => ({
            ...values,
            ...additionalInformationsResult.items?.find(items => values.geoId === items.cityId),
          }))
        : null,
    [resultArea.items, additionalInformationsResult.items, isAreaCensus],
  )

  const resultMergePopulation = mergePopulation
    ? Number(mergePopulation[0]?.count) / Number(mergePopulation[0]?.highlight)
    : null

  const resultMergeArea = mergeArea
    ? Number(mergeArea[0]?.count) / Number(mergeArea[0]?.highlight)
    : null

  useEffect(() => {
    if (
      level &&
      (level.geo === 'country' ||
        level.geo === 'macroRegion' ||
        level.geo === 'state' ||
        level.geo === 'city')
    ) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [level])

  const additionalInfo = useMemo(
    () =>
      additionalInformationsResult.items
        ? formatAsBrNumber(
            additionalInformationsResult.items.reduce(
              (acc, item) => acc + Number(item.count ?? 0),
              0,
            ),
          )
        : null,
    [additionalInformationsResult.items],
  )

  const additionalInfoLegalEntityActive = useMemo(
    () =>
      additionalInformationsLegalEntityActiveResult.items
        ? formatAsBrNumber(
            additionalInformationsLegalEntityActiveResult.items.reduce(
              (acc, item) => acc + Number(item.count ?? 0),
              0,
            ),
          )
        : null,
    [additionalInformationsLegalEntityActiveResult.items],
  )

  useEffect(() => {
    const shouldSetLoading =
      (additionalInformationsResult.isLoading &&
        ((filter && filter.value) || (highlight && highlight.value))) ||
      isLoadingMainTotal
        ? true
        : false

    setIsLoading(shouldSetLoading)
  }, [isLoadingMainTotal, additionalInformationsResult.isLoading, filter, highlight])

  useEffect(() => {
    if (additionalInformationsResult) {
      handleAdditionalInfoIsLoading(
        additionalInformationsResult?.isLoading || isLoading || isLoadingMainTotal,
      )
    }

    if (!isEnabled) {
      handleAdditionalInfo(null)
    } else if (isEnabled && additionalInfo) {
      if (highlight && definedOption === 'customized') {
        handleAdditionalInfo(`${additionalInfo}, ${additionalInfoLegalEntityActive}`)
      }

      if (isLoading || additionalInformationsResult.isLoading || isLoadingMainTotal) {
        handleAdditionalInfo('Carregando...')
      } else if (additionalInfo) {
        if (isPopulationCensus) {
          handleAdditionalInfo(
            `${formatAsBrNumber(Number(resultMergePopulation?.toFixed(2)))} empresas per capita`,
          )
        } else if (isAreaCensus) {
          handleAdditionalInfo(
            `${formatAsBrNumber(Number(resultMergeArea?.toFixed(2)))} empresas por km²`,
          )
        } else if (indexOptions.includes(definedOption) && additionalInfoLegalEntityActive) {
          handleAdditionalInfo(`${additionalInfo}, ${additionalInfoLegalEntityActive}`)
        } else if (!indexOptions.includes(definedOption) && !highlight) {
          handleAdditionalInfo(`${additionalInfo}`)
        } else if (highlight && highlight.value === 'isMei') {
          handleAdditionalInfo(`${additionalInfo}, ${additionalInfoLegalEntityActive}`)
        }
      }
    }
  }, [
    isEnabled,
    isLoading,
    additionalInfo,
    additionalInformationsResult?.isLoading,
    additionalInformationsLegalEntityActiveResult?.isLoading,
    isLoadingMainTotal,
    isPopulationCensus,
    resultMergePopulation,
    isAreaCensus,
    resultMergeArea,
    highlight,
    handleAdditionalInfo,
  ])
}
