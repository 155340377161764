import { KeycloakTokenParsed } from 'keycloak-js'
import { errors, truthy, User } from '~/prix'

export const ameiRoleAndPrixRole = {
  ADMINISTRADOR: 'administrator' as 'administrator',
  ADMINISTRADOR_SISTEMA: 'systemAdministrator' as 'systemAdministrator',
  APP_SEBRAE: 'appSebrae' as 'appSebrae',
  UGE: 'uge' as 'uge',
  GESTOR_NACIONAL: 'manager' as 'manager',
  GESTOR_ESTADUAL: 'stateManager' as 'stateManager',
  AGENTE: 'agent' as 'agent',
  GESTOR_APP_SEBRAE: 'appSebraeManager' as 'appSebraeManager',
}
const systemAdministratorEmails = [
  'cto@prix.tech',
  'contato@prix.tech',
  'ceo@prix.tech',
  'leonardo@prix.tech',
  'jorgenjr@outlook.com',
]

export default function tokenParsedToUser(tokenParsed: KeycloakTokenParsed) {
  if (systemAdministratorEmails.includes(tokenParsed.email)) {
    const user: User = {
      id: tokenParsed.sub as string,
      roles: ['systemAdministrator'],
      email: tokenParsed.email as string,
      name: tokenParsed.name as string,
      cpf: (tokenParsed.cpf || tokenParsed.upn || tokenParsed.preferred_username || null) as
        | string
        | null,
      stateAbbreviation: (tokenParsed.uf || null) as string | null,
      isEmailVerified: !!tokenParsed.email_verified as boolean,
    }
    return user
  }

  if (!tokenParsed.resource_access || !tokenParsed.resource_access['prix-tech']) {
    throw errors.permission(
      'Você precisa de uma permissão no AMEI ao recurso da Prix Tech para acessar a plataforma',
    )
  }

  const roles = tokenParsed.resource_access['prix-tech'].roles
    .map(role => ameiRoleAndPrixRole[role as keyof typeof ameiRoleAndPrixRole])
    .filter(truthy)

  if (!roles.length) {
    throw errors.permission(
      'Você precisa de ao menos um papel configurado no AMEI para acessar a plataforma',
    )
  }

  const user: User = {
    id: tokenParsed.sub as string,
    roles,
    email: tokenParsed.email as string,
    name: tokenParsed.name as string,
    cpf: (tokenParsed.cpf || tokenParsed.upn || tokenParsed.preferred_username || null) as
      | string
      | null,
    stateAbbreviation: (tokenParsed.uf || null) as string | null,
    isEmailVerified: !!tokenParsed.email_verified as boolean,
  }
  return user
}
