import { count, entity, equals, query, string, truthy, notNull, isNull } from '~/prix'

export default function microQuery(stateAbbreviation?: string) {
  return query('legalEntity')
    .select({
      total: count(entity('legalEntity').property('id')),
      count: count(equals(entity('legalEntity').property('size'), string().value('micro'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? notNull(entity('legalEntityGeoprocessing').property('fullAddressHash'))
          : null,
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('legalEntity').property('state'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .limit(1)
}
