import {
  entity,
  equals,
  query,
  string,
  count,
  isNull,
  truthy,
  likeAny,
  notNull,
  boolean,
  number,
  between,
  distinct,
  ternary,
  some,
  every,
  isTrue,
  isFalse,
} from '~/prix'
import {
  GeoLegalEntitiesPossibilities,
  optionsBooleanValue,
} from '../legalEntityGeoprocessingMapLevels.data'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import { extractDates } from '../definedQueries/utils'
import { format } from 'date-fns'

export default function additionalInformationQuery(
  by: GeoLegalEntitiesPossibilities,
  id: string,
  filter: FilterHighlight | null,
  highlight?: FilterHighlight | null,
  definedOption?: any,
) {
  if (
    by === 'neighborhood' ||
    by === 'street' ||
    by === 'clusteredLegalEntity' ||
    by === 'legalEntity'
  ) {
    return {}
  }

  const [filterStartDate, filterFinalDate] =
    filter && filter.value && typeof filter.value === 'string' && filter.type === 'attendance'
      ? extractDates(filter.value)
      : []

  const [highlightStartDate, highlightFinalDate] =
    highlight &&
    highlight.value &&
    typeof highlight.value === 'string' &&
    highlight.type === 'attendance'
      ? extractDates(highlight.value)
      : []

  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  switch (by) {
    case 'country':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(
            ternary(
              some(
                isNull(entity('legalEntity').property('state')),
                equals(entity('legalEntity').property('state'), string().value('EX')),
              ),
              entity('legalEntity').property('id'),
              number().value(null as any),
            ),
          ),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)

    // Macro
    case 'macroRegion':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(isNull(entity('legalEntity').property('state'))),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)

    case 'state':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(
            distinct(
              ternary(
                isNull(entity('legalEntityGeoprocessing').property('cityId')),
                entity('legalEntity').property('id'),
                number().value(null as unknown as number),
              ),
            ),
          ),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'inner',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)

    case 'mesoRegion':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(isNull(entity('legalEntityGeoprocessing').property(`microRegionId`))),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'inner',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)

    // Micro
    case 'microRegion':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(isNull(entity('legalEntity').property(`city`))),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'inner',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,
            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)

    // Cidade
    case 'city':
      return query('legalEntity')
        .select({
          total: count(entity('legalEntity').property('id')),
          count: count(
            distinct(
              ternary(
                isNull(entity('legalEntityGeoprocessing').property('neighborhoodId')),
                entity('legalEntity').property('id'),
                number().value(null as unknown as number),
              ),
            ),
          ),
          // cityId: entity('city').property('id'),
        })
        .join({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityGeoprocessing').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value) ||
          (highlight && highlight.type === 'attendance' && highlight.value)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('legalEntityAttendance').property('legalEntityId'),
          join: 'left',
        })
        [
          (filter && filter.type === 'attendance' && filter.value && filter.attendanceSource) ||
          (highlight &&
            highlight.type === 'attendance' &&
            highlight.value &&
            highlight.attendanceSource)
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntityAttendance').property('id'),
          target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
          join: 'left',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('legalEntity').property('id'),
          target: entity('courseRegisterIndividual').property('legalEntityId'),
          join: 'inner',
        })

        [
          filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
          filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadQuery' ||
          filter?.definedOption === 'legalEntityAttendanceAllCoursesEadIndexQuery' ||
          (filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1)) ||
          (highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1))
            ? 'join'
            : 'dummy'
        ]({
          current: entity('courseRegisterIndividual').property('courseProductId'),
          target: entity('courseProduct').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            definedOption === 'customized'
              ? every(
                  isNull(entity('legalEntity').property('deletedAt')),
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                )
              : null,

            filter?.type === 'size' &&
            filter?.value !== null &&
            filter?.value !== 'micro' &&
            filter?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${filter?.type}`),
                  string().value(`${filter?.value!}`),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${filter?.type}`),
                    string().value(`${filter.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            filter?.type === 'size' && filter?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            highlight?.type === 'size' &&
            highlight?.value !== null &&
            highlight?.value !== 'micro' &&
            highlight?.value !== 'isMei'
              ? equals(
                  entity('legalEntity').property(`${highlight?.type}`),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'micro'
              ? every(
                  equals(
                    entity('legalEntity').property(`${highlight?.type}`),
                    string().value(`${highlight.value!}`),
                  ),
                  isFalse(entity('legalEntity').property('isMei')),
                )
              : null,

            highlight?.type === 'size' && highlight?.value === 'isMei'
              ? isTrue(entity('legalEntity').property('isMei'))
              : null,

            (filter?.type === 'attendance' && filter?.value) ||
            (highlight?.type === 'attendance' && highlight?.value)
              ? notNull(entity('legalEntityAttendance').property('legalEntityId'))
              : null,

            filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(filter?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(filter?.attendanceSource)),
                )
              : null,

            highlight?.type === 'attendance' && highlight?.value && highlight?.attendanceSource
              ? equals(
                  entity('legalEntityAttendanceDataSource').property(`dataSourceId`),
                  Number(highlight?.attendanceSource) === 1
                    ? number().value(Number(5))
                    : number().value(Number(highlight?.attendanceSource)),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 3 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            (Number(filter?.attendanceSource) === 5 || Number(filter?.attendanceSource) === 1) &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !filter?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(filterStartDate),
                  !filter?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            filter?.type === 'attendance' &&
            Number(filter?.attendanceSource) === 6 &&
            filterStartDate &&
            filterFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !filter
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(filterStartDate),
                  !filter
                    ? entity('dataSource').property('endDate')
                    : string().value(filterFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 3 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            (Number(highlight?.attendanceSource) === 5 ||
              Number(highlight?.attendanceSource) === 1) &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('courseRegisterIndividual').property('registerDate'),
                  !highlight?.value
                    ? string().value(firstDayOfCurrentYearFormatted)
                    : string().value(highlightStartDate),
                  !highlight?.value
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            highlight?.type === 'attendance' &&
            Number(highlight?.attendanceSource) === 6 &&
            highlightStartDate &&
            highlightFinalDate
              ? between(
                  entity('legalEntityAttendance').property('startDate'),
                  !highlight
                    ? entity('dataSource').property('lastYearDate')
                    : string().value(highlightStartDate),
                  !highlight
                    ? entity('dataSource').property('endDate')
                    : string().value(highlightFinalDate),
                )
              : null,

            filter?.definedOption === 'legalEntityAttendancePortfolioEadQuery' ||
            filter?.definedOption === 'legalEntityAttendancePortfolioEadIndexQuery' ||
            (filter?.type === 'attendance' && Number(filter?.attendanceSource) === 1) ||
            (highlight?.type === 'attendance' && Number(highlight?.attendanceSource) === 1)
              ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
              : null,

            filter?.type === 'cnae' && filter?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  filter?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            highlight?.type === 'cnae' && highlight?.value
              ? likeAny(
                  entity('legalEntity').property('cnaes'),
                  highlight?.value
                    .toString()
                    .split(',')
                    .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                  { sensitive: true },
                )
              : null,

            filter?.type === 'legalNature' && filter?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${filter?.value!}`),
                )
              : null,

            highlight?.type === 'legalNature' && highlight?.value
              ? equals(
                  entity('legalEntity').property('legalNatureId'),
                  string().value(`${highlight?.value!}`),
                )
              : null,

            optionsBooleanValue.includes(`${filter?.type}`)
              ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
              : null,

            optionsBooleanValue.includes(`${highlight?.type}`)
              ? equals(entity('legalEntity').property(`${highlight?.type}`), boolean().value(true))
              : null,
          ].filter(truthy),
        )
        .limit(1)
  }
}
