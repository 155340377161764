import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: 'flex';
  flex: 1;

  img {
    width: 100%;
    height: 165px;
    object-fit: fill;
  }

  .text {
    padding: 14px 10px 10px 10px;
  }

  .bottom {
    display: flex;
    justify-content: center;
    padding: 5px 0px 10px 0px;

    a {
      color: #005eb8;

      &:hover {
        color: #0f438a;
      }
    }
  }
`

export default function DashboardGridItemCourseLifeCycle() {
  return (
    <Wrapper>
      <img src='https://academiadoecommerce.com/uploads/images/2020/07/231-1595508924.png' />
      <div className='text'>
        <span>
          Um estudo sobre ciclo de vida do produto, aplicado sobre os cursos do portfólio EaD.
        </span>
      </div>
      <div className='bottom'>
        <a href={'/app/report/course-life-cycle'}>Ver mais</a>
      </div>
    </Wrapper>
  )
}
