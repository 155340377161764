import { format } from 'date-fns'
import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  isNull,
  descending,
  every,
  greaterOrEqual,
  date,
  ternary,
} from '~/prix'

export default function mostUsedCitiesQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId: number
}) {
  const currentDate = new Date()
  const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1)
  const firstDayOfCurrentYearFormatted = format(firstDayOfCurrentYear, 'yyyy-MM-dd')

  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property('cityId'),
      geoName: entity('city').property('name'),
      totalLegalEntity: count(
        distinct(
          ternary(
            isNull(entity('legalEntity').property('deletedAt')),
            entity('legalEntityGeoprocessing').property('legalEntityId'),
            number().value(null as unknown as number),
          ),
        ),
      ),
      totalAttendances: count(
        every(
          greaterOrEqual(
            entity('legalEntityAttendance').property('startDate'),
            date().value(`${firstDayOfCurrentYearFormatted}`),
          ),
          equals(
            entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
            number().value(dataSourceId),
            entity('legalEntityGeoprocessing').property('legalEntityId'),
          ),
        ),
      ),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('state').property('id'), string().value(stateId))
          : null,
      ].filter(truthy),
    )
    .order(descending('totalAttendances'))
}
